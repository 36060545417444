.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 30px;
    background-color: #94bbe9;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    width: 80px;
    height: auto;
    border-radius: 20px;
    position: fixed;
    top: 30px;
    left: 50px;
    transform: translateX(-40%);
}

.nav__menu {
    list-style: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background: rgba(255, 255, 255, 0.25); */
    box-shadow: 0 8px 23px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(100px);
    border-radius: 20px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 30px;
    left: -500px;
    /* align-items: stretch; */
    padding: 3px 0;
    /* margin: 0; */
    z-index: -1;
    /* transition: transform 0.6s cubic-bezier(0.2, 0.5, 0.44, 1) 0s; */
    transition: background 1.5s, color 1.5s, transform 0.3s;
    /* animation: fadeInBox 1s; */
    transform: translateX(111%);


}

.nav-links {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links:hover {
    background-color: #E9C294;
    border-radius: 20px;
    /* transition: all 0.2s ease-in-out; */
}

.fa-bars, 
.fa-times {
    color: #fff;

}

.menu-icon {
    display: block;
}
.nav__menu.active {
    transform: translateX(500px);
    top: 30px;
}


@media screen and (max-width: 850px) {
    .navbar {
        z-index: 99;
        left: 10%;
        
    }

    .nav__menu {
        left: -250px;
        height: auto;

    }
    .nav__menu.active {
        transform: translateX(250px);
        top: 30px;
    }

    
}
.nav__menu.active ~ .navbar {
    background-color: transparent;
    box-shadow: none;
}

.nav-links {
    display: block;
    widows: 100%;
    padding: 2rem 0;
    color: rgba(0, 0, 0, 0.75);
}

.nav-links:hover {
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
    width:90%;
    margin: auto;
}

/* @keyframes fadeInBox {
    0% { box-shadow:0 0 0 0; }
    100% { box-shadow: 0 8px 23px 0 rgba(31, 38, 155, .37); }
  }
   */

   @media (prefers-color-scheme: dark) {
        .navbar {
            background-color: #000;
            outline: 1px solid white;
        }

        .title {
            color: #f1f1f1;
        }
        .nav-links {
            color: #f1f1f1;
        }

        .nav-links:hover {
            background-color:#376ba7;
        }
   }