.home {
    width: 100%;
    height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;

    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);

}

.fourohfour {
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    display: flex;
    text-align: center;
}
.fof__break {
    /* flex-basis: 100%; */
    height: 0;
    padding-bottom: 15px;
}

@media screen and (min-width: 1000px) {

    .device {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        
}


@media (prefers-color-scheme: dark) {
    .theme {
        background-color: black;
        color:aqua;
    }
}
