.list__head {
/* padding-top: 150px; */
    padding: 50px 100px 100px;
    list-style: none;
    /* display: flex; */
    margin: bottom 10px;

}

.project__link {
    text-decoration: none;
    color:whitesmoke;
}


.project__icon {
    border-radius: 20px;
    width: 100px;
    /* margin-top: 50px; */
}


.project__description {
    color: rgb(233, 231, 231);
    padding-bottom: 100px;
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: large;
    max-width: 1000px;

}

.bg {
    width: 100%;
    /* height: 100vh; */
    padding-top: 20px;
    padding-bottom: 20px;

    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);

}

.demo {
    display: flex;
    flex-direction: row ;
    align-items: center;
}
.demo img {
    height: 50%;
    width: 25%;
    object-fit: contain;
    margin-top: -50px;
    padding-bottom: 50px;
}

@media screen and (max-width: 850px) {

    .demo img {
        height: 100%;
        width: 50%;
    }
    .list__head {
        padding: 50px 50px 100px;        
    }
        
}

@media (prefers-color-scheme: dark) {
    .bg {
        background: rgb(56, 39, 46);
        background: radial-gradient(circle, rgb(7, 3, 5) 0%, rgb(28, 36, 45) 100%);

    }
}
