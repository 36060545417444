* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    scroll-behavior: smooth;
}

.App {
    text-align: center;
}