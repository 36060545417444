.header {
    padding-top: 75px;
    padding-left: 5%;
}

.title {
    font-size: 72px;
}
.name {
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  
}
.columns {
    margin-bottom: 50px;
}

.circular {
    border-radius: 50%;
    object-fit: cover;
    width: 300px;
    height: 300px;
    outline: 5px solid white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 1);

    /* outline: 5px solid black; */
}
.desktop {
    display: none;
}


@media (prefers-color-scheme: dark) {
    .circular {
        outline: 5px solid black;
    }
}

@media screen and (min-width: 1000px) {

    .title {    
        display: none;    
    }
    .name {
        display: none;
    }
    .desktop {
        font-size: 60px;
        display: flex;
        width: 110%;
        /* align-items: center; */
        /* background-color: black; */
    }
    .center {
        display: flex;
        justify-content: center;
    }
        
}
