.header {
    padding: 75px 5%;
}
h1 {
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
a {
    text-decoration: none;
}
.svg {
    padding-left: 10px;
    width: 33px;
}
.widthBlock {
    max-width: 1000px;
}

@media screen and (max-width: 850px) {

    .svg {
        padding-left: 20px;
        padding-top: 20px;
        width: 60px;
    }
            
}

@media (prefers-color-scheme: dark) {
    a {
        color: white;
    }
}
